import request from '@/utils/request';
import { params } from '@/views/google/create/components/trackCreat';

const BASEURL = process.env.VUE_APP_GOOGLE_BASEURL;
// const APIFOXURL = 'https://apifoxmock.com/m1/4483932-4130578-default/google';
const APIFOXURL = process.env.VUE_APP_API_YOUTUBE;
const POST = (url, data, config) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    ...config,
  });

const GET = (url, params, config) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'get',
    params,
    ...config,
  });
// 获取广告创建常量
export const adConstMapGG = (params) => GET('/google/ads/adConstsMap', params);

// 通过链接获取商品站点信息
export const promoteInfo = (params) => GET('/manage/ads/promoteInfo', params, { baseURL: APIFOXURL });

// 获取常用地区
export const commonUseLocations = (params) => GET('/manage/account/listCommonLocation', params, { baseURL: APIFOXURL });

// 获取常用受众列表
export const listCommonInterests = (params) =>
  GET('/manage/audience/listCommonAudiences', params, { baseURL: APIFOXURL });

//   删除常用受众
export const delCommonInterests = (params) => GET('/manage/audience/delCommonAudience', params, { baseURL: APIFOXURL });

//   保存常用受众
export const saveCommonInterests = (params) =>
  POST('/manage/audience/saveCommonAudiences', params, { baseURL: APIFOXURL });

//   创建自定义受众
export const createCustomerAudience = (params) =>
  POST('/manage/audience/createCustomAudience', params, { baseURL: APIFOXURL });

//   获取自定义受众列表
export const listCustomerAudience = (params) =>
  GET('/manage/audience/listCustomAudience', params, { baseURL: APIFOXURL });

//   删除自定义受众
export const delCustomerAudience = (params) =>
  GET('/manage/audience/delCustomAudience', params, { baseURL: APIFOXURL });
//   获取账户受众包列表
export const accountAudiences = (params) => GET('/manage/audience/accountAudiences', params, { baseURL: APIFOXURL });
//   上传图片
// export const uploadImg = (params) => {
//   POST('/manage/asset/uploadOne', params, { baseURL: APIFOXURL });
// };
export const uploadImg = (file, accountId, config) => {
  let data = new FormData();
  data.append('file', file.raw);
  data.append('accountId', accountId);
  return request({
    baseURL: APIFOXURL,
    url: '/manage/asset/uploadOne',
    method: 'post',
    data,
    timeout: 0,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
    onUploadProgress: (progressEvent) => {
      progressEvent.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      config && config.onUploadProgress ? config.onUploadProgress(progressEvent, file) : null;
    },
  });
};

// 拆分预览

export const createPreview = (params) => POST('/manage/ads/preview', params, { baseURL: APIFOXURL });

// 创建广告
export const createCampaign = (params) => POST('/manage/ads/createCampaign', params, { baseURL: APIFOXURL });

// 获取广告详情
export const adDetail = (params) => POST('/ads/mutate/adDetail', params, { baseURL: APIFOXURL });

// 保存广告
export const updateAd = (params) => POST('/ads/mutate/updateAd', params, { baseURL: APIFOXURL });

// 检查账户是否有可用凭证
export const supportCreateAd = (params) => GET('/google/account/supportCreateAd', params);

// 创建受众群体
export const createOrUpdateAudience = (params) =>
  POST('/manage/audience/createOrUpdateAudience', params, { baseURL: APIFOXURL });

//   查询受众使用情况
export const getUsedAdInfos = (params) => POST('/manage/audience/getUsedAdInfos', params, { baseURL: APIFOXURL });

// 获取复制需要的草稿箱参数
export const draftFromCopyParam = (params) =>
  POST('/manage/ads/draftFromCopyParam', params, { baseURL: APIFOXURL, timeout: 0 });
// 检查网址
export const checkUrl = (params) => GET('/manage/audience/checkUrl', params);
