import request from '@/utils/request';

const BASEURL = process.env.VUE_APP_GOOGLE_BASEURL;

const POSTFORM = (url, data) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });

const POST = (url, data) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });

const GET = (url, params) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'get',
    params,
  });

/**
 * @description: 删除我的筛选条件
 * @param {*} param
 * @return {*}
 */
export const deleteFilter = (param) => POSTFORM('/ads/deleteFilter', param);

/**
 * @description: 广告系列，分组，广告的数量统计
 * @param {*} param
 * @return {*}
 */
export const getAdsCount = (param) => GET('/google/ads/adsCount', param);

/**
 * @description: 列表
 * @param {*} data
 * @param {enum} data.level campaign, adset, ad
 * @return {*}
 */
export const getAdsReport = (data) => POST('/google/ads/report', data);

/**
 * @description: 修改广告系列，广告组，广告状态
 * @param {*} data
 * @return {*}
 */
export const mutateStatus = (data) => POST('/google/ads/mutateStatus', data);

/**
 * @description: 修改广告系列，广告组，广告名称
 * @param {*} data
 * @return {*}
 */
export const mutateName = (data) => POST('/google/ads/mutateName', data);

/**
 * @description: 搜索草稿广告系列
 * @param {*} data
 * @return {*}
 */
export const searchDraftCampaign = (data) => POST('/google/ads/searchDraftCampaign', data);

/**
 * @description: 上传广告系列草稿
 * @param {*} data
 * @return {*}
 */
export const uploadDraftCampaignAsync = (data) => GET('/google/ads/uploadDraftCampaignAsync', data);

/**
 * @description: 查询条件->我的查询条件
 * @param {*} data
 * @return {*}
 */
export const myFilters = (data) => GET('/google/ads/myFilters', data);

/**
 * @description: 查询条件->保存我的查询条件
 * @param {*} data
 * @return {*}
 */
export const saveFilter = (data) => POST('/google/ads/saveFilter', data);

/**
 * @description: 我的自定义列
 * @param {*} data
 * @return {*}
 */
export const myColumns = (data) => GET('/google/ads/myColumns', data);

/**
 * @description: 自定义列，所有列
 * @param {*} data
 * @return {*}
 */
export const allColumns = (data) => GET('/google/ads/allColumns', data);

/**
 * @description: 保存自定义列
 * @param {*} data
 * @return {*}
 */
export const saveColumn = (data) => POSTFORM('/google/ads/saveColumn', data);

/**
 * @description: /ads/myFilters
 * @param {*} data
 * @return {*}
 */
export const deleteColumn = (data) => POSTFORM('/google/ads/deleteColumn', data);

/**
 * @description:
 * @param {*} data
 * @return {*}
 */
export const reportConsts = (data) => GET('/google/ads/reportConsts', data);

export const updateAdGroupCriterion = (data) => POST('/google/ads/updateAdGroupCriterion', data);

// 搜索站点
export function searchSites(params) {
  return GET('/sale/searchSites', params);
}

// 查询-谷歌广告系列-ai建议
export const googleRecommendApi = (data) => GET('/google/ads/recommend', data);

// 采纳-谷歌广告系列-ai建议
export const googleApplyRecommendApi = (data) => GET('/google/ads/applyRecommend', data);

/**
 * @description: 搜索草稿广告系列
 * @param {*} data
 * @return {*}
 */
export const getDraftCampaignListApi = (data) => POST('/manage/ads/getDraftCampaignList', data);

/**
 * @description: 上传广告系列草稿
 * @param {*} data
 * @return {*}
 */
export const uploadDraftByIdsApi = (data) => POST('/manage/ads/uploadDraftByIds', data);

/**
 * @description: 上传广告系列草稿
 * @param {*} data
 * @returns
 */
export const uploadDraft = (data) => POST('/manage/ads/uploadDrafts', data);

/**
 * @description: 保存草稿
 * @param {*} data
 * @returns
 */
export const saveDrafts = (data) => POST('/manage/ads/saveDrafts', data);
