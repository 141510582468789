import request from '@/utils/request';
import { params } from '@/views/google/create/components/trackCreat';

const BASEURL = process.env.VUE_APP_GOOGLE_BASEURL;
const APIFOXURL = 'https://apifoxmock.com/m1/4483932-4130578-default/google';
const BASEYOUTUBE = process.env.VUE_APP_API_YOUTUBE;
const POST = (url, data, config) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    ...config,
  });

const GET = (url, params, config) =>
  request({
    url,
    baseURL: BASEURL,
    method: 'get',
    params,
    ...config,
  });
//   获取频道列表
export const getChannelPageList = (params) => POST('/youtube/manage/channelPage', params, { baseURL: BASEYOUTUBE });
//   更改频道状态
export const setChannelStatus = (params) =>
  POST('/youtube/manage/batchChannelStatus', params, { baseURL: BASEYOUTUBE });
// 删除频道
export const deleteChannel = (params) => POST('/youtube/manage/deleteChannel', params, { baseURL: BASEYOUTUBE });

// 获取所有的频道列表
export const getChannelList = (params) => GET('/youtube/manage/channelList', params, { baseURL: BASEYOUTUBE });

// 授权youtube凭证
export const getAuthUrl = (params) => GET('/auth/getAuthUrl', params, { baseURL: BASEYOUTUBE });

// 获取上传视频进度
export const getVideoProcess = (params) =>
  GET(
    `/youtube/manage/getUploadProcessByIds?recordIds=${params.recordIds}&searchStatus=${params.searchStatus}`,
    {},
    { baseURL: BASEYOUTUBE },
  );

//   获取视频上传记录
export const videoRecord = (params) => POST(`/youtube/manage/videoRecord`, params, { baseURL: BASEYOUTUBE });

// 上传记录详情
export const videoRecordDetail = (params) =>
  POST(`/youtube/manage/videoRecordDetail`, params, { baseURL: BASEYOUTUBE });

// 同步频道
export const syncChannels = (params) => POST(`/youtube/manage/syncChannels`, params, { baseURL: BASEYOUTUBE });

// 获取频道用户
export const channelUserList = (params) => GET(`/youtube/manage/channelUserList`, params, { baseURL: BASEYOUTUBE });

//添加频道用户
export const batchAddChannelUser = (params) =>
  POST(`/youtube/manage/batchAddChannelUser`, params, { baseURL: BASEYOUTUBE });

// 获取用户列表
export const queryGkUser = (params) => GET(`/youtube/manage/queryGkUser`, params, { baseURL: BASEYOUTUBE });

// 删除频道用户
export const deleteChannelUser = (params) =>
  POST(`/youtube/manage/deleteChannelUser`, params, { baseURL: BASEYOUTUBE });
// 异步上传视频
export const asyncUploadVideo = (params) => POST(`/youtube/manage/asyncUploadVideo`, params, { baseURL: BASEYOUTUBE });
// 同步上传视频
export const syncUploadVideo = (params) => POST(`/youtube/manage/syncUploadVideo`, params, { baseURL: BASEYOUTUBE });

// 获取账号下的视频
export const videoPage = (params) => GET(`/youtube/manage/videoPage`, params, { baseURL: BASEYOUTUBE });

// 编辑频道的备注
export const editChannelMark = (params) => GET(`/youtube/manage/channelMark`, params, { baseURL: BASEYOUTUBE });
